import * as React from 'react'
import Template from "../../components/template";
import {Link} from "gatsby";
import {projectsData} from "../../components/projects_data"
import Project from "../../components/project";

const AboutPage = () => {
    const p = projectsData.find(item => item.name === "AppMetr")
    return (
        <Template title="AppMetr">
            <div className="block text-sm breadcrumbs">
                <ul>
                    <li>
                        <Link to="/">Home</Link>
                    </li>
                    <li>
                        <Link to="/projects/">Projects</Link>
                    </li>
                    <li><h2 className="font-bold">AppMetr</h2></li>
                </ul>
            </div>
            <div className="block space-y-2">
                <Project p={p} full={true}/>
            {/*    /!*<h2 className="text-xl">AppMetr</h2>*!/*/}
            {/*    <p>The architecture of in-house distributed, high-load, fault-tolerant analytical system AppMetr. It handles more than 1 billion events per day, stores more than 100 Tb of data.</p>*/}
            {/*    <p>The main features are an efficient in-house columnar database (I've created it), near real-time analytics, schemaless custom events, powerful UI for the end-users.</p>*/}
            {/*    <p>The system provides a fast way to filter, group, analyze a huge amount of events from mobile devices and servers in seconds. It allows inspecting the lifecycle of an individual user or some user group. Besides base features, it provides advanced analytics like cohort analysis, user retention analysis, etc.</p>*/}
            {/*    <p>We use Apache Cassandra as cold storage, Apache Kafka for queues, and in-house Java software for high-performance querying.</p>*/}
            {/*    <p>You can get more details from this <a href="https://www.youtube.com/watch?v=F_moPIR-cNU" target="_blank" rel="nofollow">video</a> or other videos in the <Link to="/media/">media section</Link>.</p>*/}
            {/*    <p>Managed a team of 5 Software Engineers.</p>*/}
            {/*    <p>Was a speaker at Saint HighLoad++ 2019 and Joker 2019 conferences.</p>*/}
            </div>
        </Template>
    )
}

export default AboutPage
